<!-- @format -->

<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>

<script>
import { login, get_share_url } from './request/api.js'
import wx from 'weixin-js-sdk'

import './style/base.css'
export default {
	data() {
		return {
			share: {},
			kefuShow: true,
			g: '',
			gShow: false,
			countDown: 1,
			countDonwShow: true,
		}
	},
	watch: {
		// 监听路由对象中的变化
		$route: {
			handler: function (to, from) {
				// 当路由发生变化时执行的逻辑
				console.log(to, from)
				if (to.path != '/detail') {
					var url = window.location.href
					get_share_url({
						url: encodeURIComponent(url),
					}).then((res) => {
						console.log(res)
						wx.config({
							debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: [
								'updateAppMessageShareData',
								'updateTimelineShareData',
								'hideMenuItems',
							], // 必填，需要使用的 JS 接口列表
						})
						wx.ready(function () {
							wx.hideMenuItems({
								menuList: ['menuItem:copyUrl'],
							})
							//需在用户可能点击分享按钮前就先调用
							wx.updateAppMessageShareData({
								title: '童心寄中华·共绘中国梦——中华人寿童享六一秀才艺赢好礼~', // 分享标题
								desc: '', // 分享描述
								link: 'https://h5.lyhd.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
								imgUrl: 'https://h5.lyhd.vip.hnhxzkj.com/share.png', // 分享图标
								success: function () {
									// 设置成功
								},
							})
							wx.updateTimelineShareData({
								title: '童心寄中华·共绘中国梦——中华人寿童享六一秀才艺赢好礼~', // 分享标题
								desc: '', // 分享描述
								link: 'https://h5.lyhd.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
								imgUrl: 'https://h5.lyhd.vip.hnhxzkj.com/share.png', // 分享图标
								success: function () {
									// 设置成功
								},
							})
						})
					})
				}
			},
			// 设置为深度监听
			deep: true,
		},
	},
	async created() {
		var code = this.getQueryVariable('code')
		if (process.env.NODE_ENV != 'development') {
			if (!code) {
				// 微信授权登录
				window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd06935158e0acceb&redirect_uri=${window.location.href.replace(
					'#/',
					''
				)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
			} else {
				await login({
					platform: 1,
					code: code,
				}).then((res) => {
					console.log(res)
					this.userInfo = res.data.user_info
					localStorage.setItem('token', res.data.token)
					localStorage.setItem(
						'userInfo',
						JSON.stringify(res.data.user_info)
					)
				})
			}
		}
		// 	// 分享
		const Base64 = require('js-base64').Base64

		console.log(url)
		console.log(this.$route.path)
		if (this.$router.path != '/detail') {
			// 获取微信分享配置
			var url = window.location.href
			get_share_url({
				url: encodeURIComponent(url),
			}).then((res) => {
				console.log(res)
				wx.config({
					debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
					appId: res.data.appId, // 必填，公众号的唯一标识
					timestamp: res.data.timestamp, // 必填，生成签名的时间戳
					nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
					signature: res.data.signature, // 必填，签名
					jsApiList: [
						'updateAppMessageShareData',
						'updateTimelineShareData',
						'hideMenuItems',
					], // 必填，需要使用的 JS 接口列表
				})
				wx.ready(function () {
					wx.hideMenuItems({
						menuList: ['menuItem:copyUrl'],
					})
					//需在用户可能点击分享按钮前就先调用
					wx.updateAppMessageShareData({
						title: '童心寄中华·共绘中国梦——中华人寿童享六一秀才艺赢好礼~', // 分享标题
						desc: '', // 分享描述
						link: 'https://h5.lyhd.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
						imgUrl: 'https://h5.lyhd.vip.hnhxzkj.com/share.png', // 分享图标
						success: function () {
							// 设置成功
						},
					})
					wx.updateTimelineShareData({
						title: '童心寄中华·共绘中国梦——中华人寿童享六一秀才艺赢好礼~', // 分享标题
						desc: '', // 分享描述
						link: 'https://h5.lyhd.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
						imgUrl: 'https://h5.lyhd.vip.hnhxzkj.com/share.png', // 分享图标
						success: function () {
							// 设置成功
						},
					})
				})
			})
		}
	},
	mounted() {},
	methods: {
		getQueryVariable(variable) {
			var query = window.location.search.substring(1)
			var vars = query.split('&')
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split('=')
				if (pair[0] == variable) {
					return pair[1]
				}
			}
			return false
		},
	},
	name: 'App',
}
</script>

<style lang="less" scoped>
.page {
	min-height: 100vh;
	background: #f4f8fb;
}

/deep/ .van-popup {
	overflow: unset;
	.close {
		position: absolute;
		left: 50%;
		bottom: 105px;
		width: 46px;
		height: 46px;
		transform: translateX(-50%);
		z-index: 11;
	}
}
/deep/ .popup-uploadSuccess {
	background-image: url(~@/assets/isUploadSuccessPopup-bg.png);
	background-size: 100%;
	width: 750px;
	height: 717px;
	padding-top: 317px;
	.num {
		display: flex;
		align-items: center;
		background-image: url(~@/assets/矩形1.png);
		background-size: 100%;
		width: 347px;
		height: 68px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;

		margin: 0 auto 14px;
		text-align: center;
		img {
			width: 66px;
			height: 70px;
			margin-right: 10px;
		}
		span {
			background: linear-gradient(
				0deg,
				#f73737 22.4365234375%,
				#ff7679 64.5263671875%
			);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: #ff4932;
		}
	}
	.text {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;
		color: #ff4932;

		background: linear-gradient(
			0deg,
			#f73737 22.4365234375%,
			#ff7679 64.5263671875%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: center;
		margin-bottom: 20px;
	}
}
/deep/ .popup-NoFrequency {
	background-image: url(~@/assets/NoFrequencyPopup-bg.png);
	background-size: 100%;
	width: 750px;
	height: 717px;
	padding-top: 333px;
	.text {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;
		color: #ff4932;

		background: linear-gradient(
			0deg,
			#f73737 22.4365234375%,
			#ff7679 64.5263671875%
		);

		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: center;
		margin-bottom: 20px;
	}
}
/deep/ .popup-vote {
	background-image: url(~@/assets/votePopup-bg.png);
	background-size: 100%;
	width: 750px;
	height: 717px;
	padding-top: 317px;
	.num {
		display: flex;
		align-items: center;
		background-image: url(~@/assets/矩形1.png);
		background-size: 100%;
		width: 347px;
		height: 68px;
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;

		margin: 0 auto 14px;
		text-align: center;
		img {
			width: 66px;
			height: 70px;
			margin-right: 10px;
		}
		span {
			background: linear-gradient(
				0deg,
				#f73737 22.4365234375%,
				#ff7679 64.5263671875%
			);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: #ff4932;
		}
	}
	.text {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;
		color: #ff4932;

		background: linear-gradient(
			0deg,
			#f73737 22.4365234375%,
			#ff7679 64.5263671875%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		text-align: center;
	}
}
/deep/ .popup-over {
	img {
		width: 750px;
		height: 716px;
	}
}
/deep/ .popup-underReview {
	img {
		width: 960px;
		height: 951px;
		display: block;
	}
}
/deep/ .popup-reviewFailed {
	img {
		width: 750px;
		display: block;
	}
	.close {
		width: 46px;
		height: 46px;
		top: 75%;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 11;
	}
}
/deep/ .popup-underReview1 {
	background-image: url(~@/assets/underReviewPopup-bg.png);
	background-size: 100%;
	width: 750px;
	height: 717px;
	padding-top: 321px;
	.text {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 40px;
		color: #ff4932;
		line-height: 65px;

		background: linear-gradient(
			0deg,
			#f73737 22.4365234375%,
			#ff7679 64.5263671875%
		);

		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin: 0 auto;
		text-align: center;
	}
}
</style>

<style lang="less">
.van-nav-bar {
	height: 80px;

	.van-nav-bar__content {
		height: 80px;

		.van-nav-bar__title {
			height: 100%;
			display: flex;
			align-items: center;
		}

		.van-nav-bar__title,
		.van-nav-bar__left,
		.van-nav-bar__right,
		.van-nav-bar__arrow {
			font-size: 24px;
		}
	}
}
.back {
	width: 100px;
	position: absolute;
	top: 24px;
	left: 0;
}
.van-toast {
	z-index: 99999 !important;
}
</style>
