/** @format */

import axios from 'axios' // 引入axios

axios.defaults.headers.post['Content-Type'] = 'application/json'
// 环境的切换
if (process.env.NODE_ENV == 'development') {
	axios.defaults.baseURL = 'https://lyhd.vip.hnhxzkj.com/api/'
	// axios.defaults.baseURL = '/dev'
} else if (process.env.NODE_ENV == 'debug') {
	axios.defaults.baseURL = 'https://lyhd.vip.hnhxzkj.com/api/'
} else if (process.env.NODE_ENV == 'production') {
	axios.defaults.baseURL = 'https://lyhd.vip.hnhxzkj.com/api/'
}
// 请求超时时间
axios.defaults.timeout = 10000000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		// var token = localStorage.getItem('token266')
		var token = localStorage.getItem('token')
		if (token != null || token != undefined) {
			token && (config.headers.common['user-token'] = `${token}`)
		}
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)
// 响应拦截器
axios.interceptors.response.use(
	(response) => {
		if (response.status === 200) {
			if (
				response.data.code == 1001 &&
				process.env.NODE_ENV == 'production'
			) {
				localStorage.removeItem('token')
			}
			return Promise.resolve(response)
		} else {
			return Promise.reject(response)
		}
	},
	// 服务器状态码不是200的情况
	(error) => {
		if (error.response.status) {
			switch (error.response.status) {
				case 1001:
					// localStorage.removeItem('cusInfo')
					// router.replace({
					//     path: '/login',
					// });
					break
				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面
				case 403:
					// Message.error('登录已过期，请重新登录！');
					break
				// 404请求不存在
				case 404:
					// Message.error('错误的网络请求');
					break
				// 其他错误，直接抛出错误提示
				default:
				// Message.error(error.response.data.message);
				// Toast({
				//     message: error.response.data.message,
				//     duration: 1500,
				//     forbidClick: true
				// });
			}
			return Promise.reject(error.response)
		}
	}
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				params: params,
			})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.post(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.put(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
